.docs-main{
	.docs-content{
		padding: 10px;
	    .btn{
	    	&::after{
	    		display: none;
	    	}
	    }
	    .widget-docs-code {
		 	margin-top: 20px;
		    pre {
			    background-color: rgba(0,0,0,0.8);
			    color: #fff;
			}
		}
		.alert{
			margin-bottom: 0px;
			.close{
				right: 0;
			}
		}
	}
}