.container-fluid{
	.login-container, .signup-container{
		width: 40%;
		margin: 2rem auto;
		.login-wrapper, .signup-wrapper{
			.login-title, .signup-title{
			    color: #fff;
			    background-color: $navbar-inverse-color;
			}
			.login-body, .signup-body{
				form{
					margin-top: 0;
				    padding: 40px 80px 30px 80px;
				    .icons{
				    	color: #fff;
					    background-color: $navbar-inverse-color;
				    }
				    .username-input{
				    	margin-bottom: 25px;
				    }
					.password-input{
				    	margin-bottom: 25px;
				    }
				    .checkbox-wrapper{
				    	margin-bottom: 25px;
				    	.remember-btn{
				    		display: inline-block;
				    		margin-right: 5px;
				    	}
				    	.remember-title{
				    		display: inline-block;
				    	}
				    }
				    .login-btn, .signup-btn{
				    	background-color: $brand-primary;
			    	    outline: none;
			    	    border: 0px;
						box-shadow: none;
						&:hover{
							background-color: darken($brand-primary, 10%);
						}
						&:active{
							box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
							background-color: darken($brand-primary, 20%);
						}
				    }
				}
				
				.input-box{
					padding-left: 15px!important;
					border: 0px;
				    box-shadow: none;
				    &:focus{
					    outline: none!important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.login-container, .signup-container{
		width: 60%!important;
	}
}

@media screen and (max-width: 800px) {
	.login-container, .signup-container{
		width: 80%!important;
	}
}

@media screen and (max-width: 600px) {
	.login-container, .signup-container{
		width: 90%!important;
	}
	.login-body, .signup-body{
		form{
			padding: 40px 15px 30px 15px!important;
		}
	}
}