@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300);

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #546e7a !important;
  background-color: #eff2f7;
  overflow-y: auto;
  overflow-x: hidden;

}

form {
  margin-top: -5px;
}

.logo {
  position: relative;
  width: 10em;
  float: left;
}

.nav-link i {
  font-size: 20px;
  margin-right: 6px;
}

.navbar-nav .nav-icons {
  display: block;
  padding-top: .425rem;
  padding-bottom: .425rem;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: 1.2em;
  position: relative;
  i {
    color: #fff !important;
    font-size: 20px;
  }

}

.profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  border: none;

}

.count {
  position: absolute;
  background-color: red;
  color: #fff;
  font-size: 11px;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  top: 19px;
  line-height: 17px;
  text-align: center;
  left: 14px;

}

.main-body-container {
  min-height: 100vh;
  overflow-x: hidden;
  padding-top: 20px;
  padding-bottom: 2rem;
  background-color: #eff2f7;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 75px;
}

.grid-bx {
  background-color: #fff;
  box-shadow: 2px 1px 9px #DCDCDC;
  -moz-box-shadow: 2px 1px 9px #DCDCDC;
}

.feeds {
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
}

.row-data {
  overflow: auto;
  max-height: 450px;
}

.row-data p {
  margin-top: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ECECEC;
  padding-bottom: 0.5rem;
}

span.label.label-pill {
  margin-top: -5px;
}

.view-link {
  color: #90a4ae !important;
  font-size: 14px !important;
  font-family: arial;
  letter-spacing: 0.5px;
}

.body-text-color {
  color: #b0bec5;
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  text-decoration: none;

}

.icon-bordered {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 20px;
  margin: 0 4px;
  padding-left: 0.8em !important;
}

.serch {
  position: absolute;
  right: 25px;
  font-size: 1rem;
  z-index: 10;
  top: 0px;
  color: #90a4ae;
}

.card .form-control {
  background-color: #eceff1 !important;
  padding: 3px 6px !important;
  border: none;
  outline: none;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 0.9rem !important;
  position: relative;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.15rem 0.75rem !important;
  font-size: 13px;
}

.text-color-default {
  color: #90a4ae;
}

.text-color-primary {
  color: #01579b;
}

.text-color-info {
  color: #1e88e5;
}

.text-color-normal {
  color: #29b6f6;
}

.text-color-danger {
  color: $brand-danger;
}

.bg-normal {
  background-color: #29b6f6;
  color: #fff;
}

.txt-right {
  text-align: right !important;
}

.txt-center {
  text-align: center;
}

.fsize18 {
  font-size: 1.5rem;
  font-weight: normal;
}

.fsize {
  font-size: 18px;
}

.fsize30 {
  font-size: 30px;
}

.m-lft35 {
  margin-left: 35px;
}

.btn-default {
  background-color: #78909c;
  color: #fff;
}

.bell {
  color: #78909c;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 10px;
}

.cont-bx {
  padding: 1rem 1rem 0 1rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  width: 100%;
  i {
    font-size: 4rem;
  }
}

.no-margin {
  margin-left: 0;
  margin-right: 0;
}

.margin-tp20 {
  margin-top: 20px;
}

.map-btn {
  border-radius: 15px 0 0 15px !important;
  box-shadow: 0px 0px 2px 1px #ccc;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 0.9rem !important;
}

.map-btn1 {
  border-radius: 0 15px 15px 0 !important;
  background-color: #b0bec5 !important;
  box-shadow: 0px 0px 2px 1px #ccc;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 0.9rem !important;
}

.mtp-10 {
  margin-top: 10px;
}

.subnav {
  background-color: #1E88E5;
  color: #fff !important;
  border-radius: 0;
  a {
    color: #fff !important;
    display: block;
    padding: 0 20px;
  }
  li.active {
    background-color: #01579B;
  }
  i {
    margin-right: 10px;
  }
  a:hover {
    text-decoration: none;
    color: #01579B;
  }
}

.bdr-btm {
  border-bottom: 1px solid #ddd;
}

.circle-default {
  color: #90a4ae;
}

.circle-info {
  color: #1e88e5;
}

.circle-primary {
  color: #01579B;
}

.circle-warning {
  color: #FFB74D;
}

.circle-danger {
  color: #FF5353;
}

.circle-success {
  color: #44b6ae;
}

.circle-default, .circle-info, .circle-danger, .circle-primary, .circle-warning, .circle-success {
  font-size: 11px;
}

#process-toggle .collapse.in {
  display: inline-block !important;
  background-color: #000 !important;
}

.rounded {
  border-radius: 50px;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-bordered {
  background-color: transparent;
  border: 2px solid;
  border-radius: 30px;
}

.btn-primary {
  &:hover {
    background-color: darken($brand-primary, 10%) !important;
    color: #fff !important;
  }
  &:active {
    color: #fff !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important;
    background-color: darken($brand-primary, 20%) !important;
    border-color: darken($brand-primary, 25%) !important;
  }
}

.text-muted {
  color: $text-muted;
}

.m-r-xs {
  margin-right: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.faorange {
  color: #FFB457;
}

.fayellow {
  color: #FFFA9B;
}

.facyan {
  color: #8AEFE6;
}

.fapurple {
  color: #DD8BFF;
}

.onoffswitch {
  position: relative;
  width: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 20px;
  padding: 0;
  line-height: 16px;
  border: 2px solid #999999;
  border-radius: 16px;
  background-color: #EEEEEE;
  margin-bottom: -4px;
  transition: background-color 0.3s ease-in;
}

.onoffswitch-label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin: 0px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 22px;
  border: 2px solid #999999;
  border-radius: 16px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #373C4F;
}

.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #373C4F;
  height: 20px;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0px;
  height: 20px;
}

.panel-primary > .panel-heading {
  background-color: $brand-default;
}
.panel-default > .panel-heading {
  background-color: $gray-lighter;
}

.panel-default > .panel-heading > .header-title {

  color: #546e7a !important;

}

.panel-control > .panelButton {
  border: none;
  background-color: transparent;
}


