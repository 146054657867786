.main-body-container{
	.profile-view{
		position: relative;
		margin: -19px -10px 1.5rem -10px;
		.cover-image{
			position: relative;
			background-image: url("../images/wallpaper4.jpg");
			height: 250px;
			background-size: cover;
			.edit-btn{
				position: absolute;
				right: 40px;
			    top: 40px;
			    width: 43px;
			    height: 43px;
			    text-align: center;
			    font-size: 22px;
			    background-color: rgba(0,0,0,0.5);
			    color: #fff;
			    border-radius: 50%;
			    padding: 4px;
			    cursor: pointer;
			    &:hover{
				    background-color: rgba(0,0,0,0.7);
			    }
			    &:active{
			    	color: $brand-primary;
			    }
			}
			.profile-name{
			    position: absolute;
			    bottom: 0;
			    width: 100%;
			    padding-left: 200px;
			    color: #fff;
			    background-color: rgba(0,0,0,0.5);
			    font-size: 30px;
			    font-weight: 100;
			    padding-top: 8px;
			    padding-bottom: 8px;
			    padding-right: 20px;
			    span{
			    	font-size: 15px;
				    padding-top: 12px;
			    }
			}
		}
		.profile-image{
		    position: absolute;
		    bottom: 70px;
		    left: 37px;
		    border: 5px solid #fff;
		    border-radius: 50%;
		}
		.profile-details{
			padding: 15px;
			margin-left: 0;
			margin-right: 0;
			max-height: 105px;
			.border-line{
				position: absolute;
			    height: 60px;
			    border-left: 1px solid lightgrey;
			    top: 10px;
			    right: 0px;
			}
			.wrapper-cols{
				&.status-wrapper{
					position: relative;
					height: 58px;
					.profile-status{
						position: absolute;
						bottom: -15px;
						.status{
						    background-color: transparent;
						    color: #00C853;
						    border: 1px solid lightgrey;
						    outline: none;
						    box-shadow: none;
						    &:after{
						    	display: none;
						    }
						    .online{
						    	padding-right: 10px;
						    }
						    .drop-down{
						    	padding-left: 10px;
						    }
						}
						.status-dropdown{
							font-size: 13px;
							.status-type{
							    padding: 5px 14px;
								&.type-online{
									color: #00C853;
								}
								&.type-away{
									color: #F8CE18;
								}
								&.type-dnd{
									color: #ff0000;
								}
								&.type-offline{
									color: darkgray;
								}
							}
						}
					}
				}
				&.details-wrapper{
					font-size: 16px;
					color: #000;
					.icons{
						padding: 5px;
						color: $brand-primary;
						border: 1px solid lightgrey;
						border-radius: 4px;
					}
					.birthday-details{
					    padding-right: 53px;
					}
				}
				&.friends-wrapper{
					.friend-title{
						font-size: 15px;
						color: darkgray;
						padding: 5px;
					}
					.friend-images{
						display: inline-block;
						img{
							width: 50px;
							height: 50px;
						}
					}
					.friends-count{
					    display: inline-block;
					    width: 50px;
					    height: 50px;
					    text-align: center;
					    color: #fff;
					    background-color: $brand-primary;
					    border-radius: 50%;
					    padding-top: 13px;
					}
				}
				&.edit-wrapper{
					height: 58px;
					.edit-btn{
						position: absolute;
						bottom: 0px;
						left: 0;
						right: 0;
						margin: 0 20px;
					}
				}
			}
		}
	}
	.comment-input-wrapper{
		.comment-input{
			width: 100%;
		    resize: none;
		    overflow: hidden;
		    line-height: 24px;
		    font-size: 15px;
		    border: 0px;
		    outline: none;
		    padding: 5px;
		}
		.comment-input-footer{
			border: 0px;
			.icons{
			    font-size: 20px;
			    padding: 10px;
			    opacity: 0.7;
			    cursor: pointer;
			    &:hover{
			    	opacity: 1;
			    }
			}
			.post-btn{
			    padding: 4px;
			    padding-left: 25px;
			    padding-right: 25px;
			    font-size: 15px;
			    font-weight: 100;
			    outline: none;
			}
		}
	}
	.comment-wrapper{
		.comment-body{
			padding-bottom: 0px;
		}
		.comment-header{
		    padding-bottom: 10px;
			.comment-profile{
				display: inline-block;
				.profile-image{
					vertical-align: top;
					display: inline-block;
					img{
						width: 46px;
						height: 48px;
					}
				}
				.profile-name{
					font-size: 18px;
					display: inline-block;
					padding-left: 10px;
					color: $brand-primary;
					.active-time{
						color: #90A4AE;
					    font-size: 13px;
					}
				}
			}
			.right-btns{
				color: #90A4AE;
				.icons{
					padding-left: 10px;
					font-size: 18px;
					display: inline-block;
					padding-right: 10px;
					&:hover{
						color: $brand-primary;
						cursor: pointer;
					}
					&.fa-heart{
						color: $brand-primary;
					}
				}
				.border-line{
					vertical-align: -3px;
					opacity: 0.3;
					border-right: 1px solid #90A4AE;
					height: 18px;
					display: inline-block;
				}
				label{
					padding-right: 10px;
				    font-size: 16px;
				}
			}
		}
		.comment-title{
			font-size: 15px;
			font-weight: 100;
			letter-spacing: 1px;
			padding: 6px;
			.border-line{
				padding-top: 20px;
			    width: 100%;
			    border-bottom: 1px solid lightgrey;
			    opacity: 0.6;
			}
			.image-container{
				img{
					max-height: 500px;
				    width: 100%;
				}
			}
		}
		.other-comment-body{
			padding: 15px;
			padding-bottom: 0px;
			img{
				width: initial!important;
				height: initial!important;
			}
			.profile-image{
				vertical-align: initial!important;
			}
			.profile-name{
				    font-size: 15px!important;
			}
			.comment-title{
				color: #90A4AE;
				padding-left: 40px;
			}
		}
		.other-comment-input{
		    padding-left: 50px!important;
		    padding-right: 20px!important;
		    padding: 15px;
		    .comment-input-box{
				color: #90A4AE;
		    	outline: none;
		    	resize: none;
		    	font-size: 15px;
				width: 100%;
			    background: #FAFAFA;
			    border: 1px solid #EDF1F2;
			    border-radius: 5px;
			    padding: 5px;
			    overflow: hidden;
		    }
		}
	}
	.chat-wrapper{
		.chat-header{
			padding: 0;
			.header-title{
				padding: 10px 15px;
			}
			.header-icons{
				.icons-group{
					.icon-wrapper{
						margin-left: 0px;
						a{
							font-size: 20px;
						    padding: 6px 13px;
							border-radius: 0px;
							background-color: $brand-primary;
							color: #fff;
						}
						&.active{
							a{
								background-color: darken($brand-primary, 15%);
							}
							.border-div{
								display: block;
							}
						}
						.border-div{
							display: none;
							border-top: 7px solid darken($brand-primary, 15%);
							border-right: 7px solid transparent;
							border-left: 7px solid transparent;
							width: 0px;
							margin-left: 15px;
						}
					}
				}
			}
		}
		.chat-body{
			.chat-profile{
			    padding-bottom: 20px;
				.profile-image{
					position: relative;
					vertical-align: top;
					display: inline-block;
					.online-symbol{
						position: absolute;
						width: 18px;
						height: 18px;
						background-color: #00C853;
						border: 5px solid #fff;
						border-radius: 10px;
						right: -3px;
						bottom: 3px;
					}
					img{
						width: 61px;
						height: 61px;
					}
				}
				.profile-name{
					font-size: 20px;
					display: inline-block;
					padding-left: 10px;
					color: $brand-primary;
					.profile-post{
						color: #90A4AE;
					    font-size: 14px;
					}
				}
			}
			.chat-input-wrapper{
			    border: 1px solid #ECEFF1;
			    border-radius: 4px;
			    margin-bottom: 20px;
				.chat-input-box{
					text-align: center;
				    background-color: transparent!important;
				    color: darkgray;
				    border: 0px;
				    box-shadow: none;
				    &:focus{
					    outline: none!important;
					}
				}
				.icons{
					background-color: transparent !important;
				    color: darkgray;
				    border: 0px;
				    cursor: pointer;
				    &:hover{
				    	color: $brand-primary;
				    }
				    &:active{
				    	background-color: $brand-primary!important;
				    	color: #fff!important;
				    }
				}
			}
			.msg-wrapper{
				text-align: center;
				.msg-time{
					padding: 5px;
				    color: darkgray;
				}
				&.self-msg-wrapper{
					.self-msg{
						text-align: left;
					    padding: 10px;
						border-radius: 7px;
						background-color: #eceff1;
						color: #546E7A;
					}
				}
				&.other-msg-wrapper{
					.other-msg{
						text-align: left;
					    padding: 10px;
						border-radius: 7px;
						color: #fff;
						background-color: $brand-primary;
					}
				}
			}
		}
	}
	.activity-wrapper{
		.activity-body{
			ul{
				padding-left: 0;
				list-style: none;
				line-height: 30px;
				font-size: 18px;
				.comment-title{
					padding-bottom: 30px;
					position: relative;
					padding-left: 35px;
					color: #90A4AE;
					.icons{
						position: absolute;
						top: 6px;
						left: 0px;
					}
					.highlight-part{
						color: $brand-primary;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1275px) {
	.main-body-container .profile-view .profile-details .wrapper-cols.details-wrapper {
	    font-size: 14px;
	    .birthday-details {
		    padding-right: 47px!important;
		}
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.friends-wrapper .friend-images .image-6 {
	    display: none;
	}
}

@media screen and (max-width: 1130px) {
	.main-body-container .profile-view .profile-details .wrapper-cols.edit-wrapper .edit-btn {
	    padding: 5px 10px;
	    font-size: 14px;
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.friends-wrapper .friend-images .image-5 {
	    display: none;
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.details-wrapper {
	    font-size: 12px;
	    .birthday-details {
		    padding-right: 42px!important;
		}
	}
	
	.profile-status{
		bottom: -8px;
	    left: 11%;
		.status{
			font-size: 11px!important;
		}
	}
}

@media screen and (max-width: 1000px) {
	.main-body-container .profile-view .profile-details .wrapper-cols.edit-wrapper .edit-btn {
	    padding: 5px 10px;
	    font-size: 11px;
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.friends-wrapper .friend-images .image-4 {
	    display: none;
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.details-wrapper {
	    .location-birthday-wrapper{
	    	display: none;
	    }
	    .phone-details{
    	    margin-right: 50%;
	    }
	    .mail-details{
	    	float: left!important;
	    }
	}
	
}
@media screen and (max-width: 850px) {
	.main-body-container .profile-view .profile-details .wrapper-cols.edit-wrapper {
	    display: none;
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.friends-wrapper{
	    display: none;
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.status-wrapper {
		width: 30%;
		.profile-status{
			bottom: -5px;
		    left: 11%;
			.status{
				font-size: 11px!important;
			}
		}
	}
	.main-body-container .profile-view .profile-image {
	    bottom: 50px!important;
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.details-wrapper {
		width: 70%;
		.border-line{
			display: none;
		}
	    .location-birthday-wrapper{
	    	display: block;
	    }
	    .phone-details{
    	    margin-right: 0;
	    }
	    .mail-details{
	    	float: right!important;
	    }
	}
	
}
@media screen and (max-width: 550px) {
	.main-body-container .profile-view .cover-image .profile-name{
		font-size: 20px!important;
		span {
		    font-size: 10px;
		    padding-top: 25px;
		}
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.details-wrapper {
	    .location-birthday-wrapper{
	    	display: none;
	    }
	    .phone-details{
    	    margin-right: 50%;
	    }
	    .mail-details{
	    	float: left!important;
	    }
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.status-wrapper {
		width: 50%;
	}
	.main-body-container .profile-view .profile-details .wrapper-cols.details-wrapper {
		width: 50%;
	}
}
