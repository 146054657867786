// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.label {
    display: inline-block;
    padding: .5em .4em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-transform: none;
    letter-spacing: 0.5px;
   
    @include border-radius();
     font-family: 'Roboto Condensed', sans-serif;
    font-weight:400;

  // Empty labels collapse automatically
  &:empty {
    display: none;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

// Add hover effects, but only for links
a.label {
  @include hover-focus {
    color: $label-link-hover-color;
    text-decoration: none;
    cursor: pointer;
  }
}

// Pill labels
//
// Make them extra rounded with a modifier to replace v3's badges.

.label-pill {
  padding-left: .9em;
  padding-right: .9em;
  border-radius: 1rem;
}


// Colors
//
// Contextual variations (linked labels get darker on :hover).

.label-default {
  background-color:#90a4ae;
  color:#fff;
}

.label-primary {
  @include label-variant($label-primary-bg);
}

.label-success {
  @include label-variant($label-success-bg);
}

.label-info {
  @include label-variant($label-info-bg);
}

.label-warning {
  @include label-variant($label-warning-bg);
}

.label-danger {
  @include label-variant($label-danger-bg);
}
