.main-body-container{
	.header-title{
		display: inline-block;
		color: white;
	}
	.header-icons{
		display: inline-block;
		color: $brand-primary;
		.icons{
			padding-left: 6px;
			cursor: pointer;
			&:hover{
				color: white;
			} 
		}
	}
	.panel-heading{
		border-color: transparent;
	}

	.revenue-wrapper{
		.panel-heading{

		}
		.revenue-body{
			position: relative;
			padding-bottom: 10px;
			.revenue-day-total{
				padding-bottom: 10px;
				.total-title{
					color: #767F8F;
					font-weight: 900;
				}
				.total-count{
					font-size: xx-large;
					color: #000;
					font-weight: 900;
					opacity: 0.8;
				}
			}
			.revenue-mtd-total{
				padding-bottom: 10px;
				.total-title{
					color: #767F8F;
					font-weight: 900;
				}
				.total-count{
					font-size: 14px;
					color: #000;
					font-weight: 900;
					opacity: 0.8;
				}
			}
		}
	}

	.margin-wrapper{
		.margin-body{
			position: relative;
			padding-bottom: 10px;
			.margin-day-total{
				padding-bottom: 10px;
				.total-title{
					color: #767F8F;
					font-weight: 900;
				}
				.total-count{
					font-size: xx-large;
					color: #000;
					font-weight: 900;
					opacity: 0.8;
				}
			}
			.margin-mtd-total{
				padding-bottom: 10px;
				.total-title{
					color: #767F8F;
					font-weight: 900;
				}
				.total-count{
					font-size: 14px;
					color: #000;
					font-weight: 900;
					opacity: 0.8;
				}
			}
		}
	}

	.trx-count-wrapper{
		.trx-count-body{
			position: relative;
			padding-bottom: 10px;
			.trx-count-day-total{
				padding-bottom: 10px;
				.total-title{
					color: #767F8F;
					font-weight: 900;
				}
				.total-count{
					font-size: 44px;
					color: #000;
					font-weight: 900;
					opacity: 0.8;
				}
			}
			.trx-count-mtd-total{
				padding-bottom: 10px;
				.total-title{
					color: #767F8F;
					font-weight: 900;
				}
				.total-count{
					font-size: 14px;
					color: #000;
					font-weight: 900;
					opacity: 0.8;
				}
			}
		}
	}

	.storage-visitor-wrapper{
		.storage-wrapper{
			.storage-body{
				position: relative;
				.storage-circle{
					width: 40%;
					display: inline-block;
				    vertical-align: 70px;
					.circle-text{
					    top: 10px!important;
					    left: -5px!important;
					    font-size: 30px!important;
					}
				}
			    .circle-title{
				    position: absolute;
				    font-size: 15px;
				    font-weight: 600;
				    top: -20px;
				    right: 20px;
				    .circle-percent{
			    	    position: absolute;
					    top: 17px;
					    right: 8px;
					    font-weight: 900;
					    font-size: 22px;
				    }
			    }
			    .circles-valueStroke{
			    	stroke: $brand-primary;
			    }
				.progress-wrapper{
					width: 58%;
					display: inline-block;
					.progress{
						width: 60%!important;
						margin-left: 7px;
					    display: inline-block;
					    .progress-bar{
					    	border-radius: 4px;
					    }
					}
					.fa{
						vertical-align: top;
					}
					span{
						padding-left: 7px;
					    display: inline-block;
					    vertical-align: top;
					    margin-top: -3px;
					    font-weight: 900;
					}
					.progress-1-wrapper{
						color: #3268FF;
						.progress-bar-1{
							background-color: #3268FF;
						}
					}
					.progress-2-wrapper{
						color: #00B4F9;
						.progress-bar-2{
							background-color: #00B4F9;
						}
					}
					.progress-3-wrapper{
						color: #00AEC3;
						.progress-bar-3{
							background-color: #00AEC3;
						}
					}
					.progress-4-wrapper{
						color: #BC09B9;
						.progress-bar-4{
							background-color: #BC09B9;
						}
					}
					.progress-5-wrapper{
						color: #FF0000;
						.progress-bar-5{
							background-color: #FF0000;
						}
					}
					.progress-6-wrapper{
						color: #f25959;
						.progress-bar-6{
							background-color: #f25959;
						}
					}
					
				}
				.storage-label{
				    position: absolute;
				    background-color: #6369A5;
				    padding: 8px;
				    padding-left: 14px;
				    padding-right: 14px;
				    font-size: 13px;
				    font-weight: 500;
				    bottom: 30px;
				    left: 15px;
				}
			}
		}
		.visitor-wrapper{
			.visitor-body{
				position: relative;
				padding-bottom: 5px;
				#visitor-chart-1 {
					max-height: 200px !important;
				    position: relative !important;
				    // max-width: 200px !important;
				}
				.visitor-week-chart{
					position: absolute!important;
					top: 100px;
					max-width: 200px!important;
					max-height: 150px!important;
				}
				.visitor-total{
					position: absolute;
					.total-title{
					    color: #767F8F;
					    font-weight: 900;
					}
					.total-count{
					    font-size: 24px;
					    color: #000;
					    font-weight: 900;
					    opacity: 0.8;
					}
				}
				.visitor-period-wrapper{	
					.visitor-period{
						width: 144px;
						margin-left: 60%;
						color: $brand-primary;
					    background-color: transparent;
					    &:after{
					    	display: none;
					    	border-top: 0.6em solid $brand-primary;
					    }
					    &:focus, &:hover, &:active, &:visited{
					    	    border-color: transparent;
					    	    outline: none;
				    	        box-shadow: initial;
					    }
					    .fa{
				    	    font-size: 11px;
						    vertical-align: 3px;
						    margin-left: 5px;
					    }
					}
					.dropdown-menu{
						min-width: 40%!important;
					}
				}
			    .dropdown-menu>li>a{
			    	color: $brand-primary;
			    }
				.open>.dropdown-toggle.btn-default {
					border-color: transparent;
				}
			}
		}
	}
	.sales-wrapper{
		.sales-body{
			.sales-info{
				display: inline-block;
				color: #000;
				font-weight: 300;
				font-size: 14px;
				.sales-count{
					color: $brand-primary;
				}
			}
			.product-dropdown{
				display: inline-block;
				.product{
				    background-color: transparent;
				    color: $brand-primary;
				    &:after{
				    	display: none;
				    	border-top: 0.6em solid $brand-primary;
				    }
				    &:focus, &:hover, &:active, &:visited{
				    	    border-color: #fff;
				    	    outline: none;
			    	        box-shadow: initial;
				    }
				    .fa{
			    	    font-size: 11px;
					    vertical-align: 3px;
					    margin-left: 5px;
				    }
			}
				.product-menu{
				    min-width: 5%;
				    position: absolute;
				    top: 120px;
				    right: 180px;
				}

			}
			.month-dropdown{
				display: inline-block;
				padding-left: 30px;
				.month{
				    background-color: transparent;
				    color: $brand-primary;
				    &:after{
				    	display: none;
				    	border-top: 0.6em solid $brand-primary;
				    }
				    &:focus, &:hover, &:active, &:visited{
				    	    border-color: #fff;
				    	    outline: none;
			    	        box-shadow: initial;
				    }
				    .fa{
			    	    font-size: 11px;
					    vertical-align: 3px;
					    margin-left: 5px;
				    }
				}
				.month-menu{
					min-width: 5%;
				    position: absolute;
				    top: 120px;
				    right: 20px;
				}

			}
			.dropdown-menu>li>a{
		    	color: $brand-primary;
		    }
			.open>.dropdown-toggle.btn-default {
				border-color: #fff;
			}
			.sales-chart{
				margin-top: 20px;
			}
		}
	}
	.graph-wrapper{
		.master-chart{
			margin-top: 25px;
		}
	}
	.purchase-wrapper{
		.purchase-body{
			.visitor-total{
				position: absolute;
				.total-count{
					font-size: 20px;
					font-weight: 700;
					color: #000;
				}
			}
			.purchase-date{
				position: absolute;
				margin: 0 25%;
				left: 0;
				right: 0;
				top: 98px;
				.date-title{
					color: $brand-primary;
					font-weight: 500;
				}
				.date-count{
					color: #000;
					font-weight: 500;
				}
			}
			.month-dropdown{
				display: inline-block;
				padding-left: 30px;
				.month{
				    background-color: transparent;
				    color: $brand-primary;
				    &:after{
				    	display: none;
				    	border-top: 0.6em solid $brand-primary;
				    }
				    &:focus, &:hover, &:active, &:visited{
				    	    border-color: #fff;
				    	    outline: none;
			    	        box-shadow: initial;
				    }
				    .fa{
			    	    font-size: 11px;
					    vertical-align: 3px;
					    margin-left: 5px;
				    }
				}
				.month-menu{
					min-width: 5%;
				    position: absolute;
				    top: 120px;
				    right: 20px;
				}
				.dropdown-menu>li>a{
			    	color: $brand-primary;
			    }
				.open>.dropdown-toggle.btn-default {
					border-color: #fff;
				}
			}
		}
		.purchase-chart{
			margin-top: 83px;
		}
	}
}

