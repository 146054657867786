.main-body-container {
  .panel-heading {
    padding: 20px;
    .panel-title {
      font-size: 20px;
    }
  }
  .page-title {
    padding: 10px;
    .title-inner {
      padding-left: 20px;
    }
    .right-icons {
      margin: 11px;
      background-color: transparent;
    }
  }
}

.alert-main {
  .dismissable-alerts {
    .alert-dismissable {
      .close {
        right: 0;
      }
    }
  }
}

.buttons-main, .dropdown-main, .modal-main {
  .demo {
    position: relative;
    &:not(.navbar-inverse) {
      .navbar-titles, .digisale-title, .nav-right .icons {
        &:not(.active) {
          color: inherit !important;
        }
      }
    }
    &:not(.navbar-inverse) {
      .navbar-titles {
        color: $gray;
        position: inherit;
        top: 30px;
        font-size: 14px;
        font-weight: 100;
      }
    }
  }
  .btn {
    &::after {
      display: none;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .btn-rounded {
    border-radius: 30px;
  }
  .btn-bordered {
    background-color: transparent;
    border: 2px solid;
    border-radius: 30px;
    &.btn-info {
      color: $brand-info;
      border-color: $brand-info;
    }
    &.btn-default {
      color: $brand-default;
      border-color: $brand-default;
    }
    &.btn-primary {
      color: $brand-primary;
      border-color: $brand-primary;
    }
    &.btn-success {
      color: $brand-success;
      border-color: $brand-success;
    }
    &.btn-warning {
      color: $brand-warning;
      border-color: $brand-warning;
    }
    &.btn-danger {
      color: $brand-danger;
      border-color: $brand-danger;
    }
  }
  .btn-circle {
    border-radius: 50%;
  }
  .btn-twitter {
    padding: 12px 16.5px;
  }
}

.breadcrumbs-main {
  .no-bg {
    background: none;
  }
  .no-m-t {
    margin-top: 0;
  }
  .m-t {
    margin-top: 1rem !important;
  }
  .line-h-ex {
    height: 1px;
    background: #eee;
    margin: 0;
  }
  .pagination-sm > li > a {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
}

.collapse-main {
  .tabs li a:hover {
    border: 1px solid #E7E7E7;
    border-bottom-color: white;
    background: inherit;
  }
  .primary-tabs li a {
    color: white;
    background: $brand-primary;
  }
  .primary-tabs li.active a, .primary-tabs li.active a:focus {
    border: 1px solid $brand-primary;
    border-bottom-color: white;
    background: inherit;
    color: #393939;
  }
  .primary-tabs li a:hover {
    border: 1px solid $brand-primary;
    border-bottom-color: white;
    background: inherit;
    color: #393939;
  }
}

.modal-main {
  .modal-table > tbody > tr > td {
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
    border-top: 0px;
  }
}

.other-elements-main {
  .list-group {
    margin-bottom: 20px;
    padding-left: 0;
  }
  .list-group-item-success {
    color: #fff;
    background-color: $brand-success;
  }
  .list-group-item-info {
    color: #fff;
    background-color: $brand-info;
  }
  .list-group-item-warning {
    color: #fff;
    background-color: $brand-warning;
  }
  .list-group-item-danger {
    color: #fff;
    background-color: $brand-danger;
  }
  .panel-body {
    .primaryb {
      background-color: $brand-primary;
    }
    .infob {
      background-color: $brand-info;
    }
    .successb {
      background-color: $brand-success;
    }
    .dangerb {
      background-color: $brand-danger;
    }
    .warningb {
      background-color: $brand-warning;
    }
    .violetb {
      background-color: #A47EC0;
    }
    .orangeb {
      background-color: #E188A4;
    }
    .cyanb {
      background-color: #D5D77C;
    }
    .carousel-text {
      height: 80px;
      padding: 20px;
      padding-top: 0;
      text-align: center;
      .carousel-indicators {
        position: absolute;
        bottom: -20px;
        left: 50%;
        z-index: 15;
        width: 60%;
        margin-left: -30%;
        padding-left: 0;
        list-style: none;
        text-align: center;
        li {
          width: 10px;
          height: 10px;
          border: 1px solid #CECECE;
          background: #CECECE;
          &.active {
            border: 1px solid $brand-primary;
            background: $brand-primary;
            width: 11px;
            height: 11px;
          }
        }
      }
      .carousel-inner {
        position: relative;
        overflow: hidden;
        width: 100%;
      }
      .carousel-control {
        background: none;
        display: none;
      }
    }
    .carousel-photo {
      .carousel-indicators {
        display: none;
      }
      .carousel-inner {
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-left: 10px;
      }
      .carousel-control {
        background: none;
      }
    }
  }
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel-sub {
  width: 46%;
  float: left;
  border: none;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -25px;
  background-color: #f0ad4e;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li > .timeline-badge > .glyphicon {
  top: 14px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning-badge {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@media (max-width: 10800px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }
  ul.timeline > li > .timeline-panel-sub {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel-sub {
    float: right;
  }

  ul.timeline > li > .timeline-panel-sub:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel-sub:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

.myIframe {
  position: relative;
  padding-bottom: 65.25%;
  padding-top: 30px;
  height: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: none;
}

.myIframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.transaction-simple {
  @media (min-width: 34em) {
    .modal-dialog {
      width: 70%;
      margin: 30px auto;
    }
  }
}

.caret{
  color: transparent;
}

.bootstrap-select>.dropdown-toggle {
  width: 100%;
  padding-right: 40px;
  z-index: 1;
}

.terminal-dashboard {
  @media (min-width: 34em) {
    .modal-body {
      position: relative;
      overflow-y: auto;
      max-height: 1400px;
      padding: 15px;
    }
  }
}
