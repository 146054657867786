//
// Basic Bootstrap table
//

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;

  th,
  td {
    padding: $table-cell-padding;
    line-height: $line-height;
    vertical-align: top;
    border-top: 1px solid $table-border-color;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight:400;

  }

  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }

  tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  .table {
    background-color: $body-bg;

  }
}


//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-sm-cell-padding;
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid $table-border-color;

  th,
  td {
    border: 1px solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: 2px;
    }
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $table-bg-accent;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      background-color: $table-bg-hover;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
@include table-row-variant(active, $table-bg-active);
@include table-row-variant(success, $state-success-bg);
@include table-row-variant(info, $state-info-bg);
@include table-row-variant(warning, $state-warning-bg);
@include table-row-variant(danger, $state-danger-bg);


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  // Todo: find out if we need this still.
  //
  // border: 1px solid $table-border-color;
  // -ms-overflow-style: -ms-autohiding-scrollbar;
  // min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
}


.thead-inverse {
  th {
    color: #fff;
    background-color: $gray-dark;
  }
}
.thead-default {
  th {
    color: $gray;
    background-color: $gray-lighter;
  }
}

.table-inverse {
  color: $gray-lighter;
  background-color: $gray-dark;

  &.table-bordered {
    border: 0;
  }

  th,
  td,
  thead th {
    border-color: $gray;
  }
}


.table-reflow {
  thead {
    float: left;
  }

  tbody {
    display: block;
    white-space: nowrap;
  }

  th,
  td {
    border-top: 1px solid $table-border-color;
    border-left: 1px solid $table-border-color;

    &:last-child {
      border-right: 1px solid $table-border-color;
    }
  }

  thead,
  tbody,
  tfoot {
    &:last-child {
      tr:last-child {
        th,
        td {
          border-bottom: 1px solid $table-border-color;
        }
      }
    }
  }

  tr {
    float: left;

    th,
    td {
      display: block !important;
      border: 1px solid $table-border-color;
    }
  }
}
