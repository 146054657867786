.navbar-inverse {
	background-color: $navbar-inverse-color;
}
.side-menu, .small-screen{
	display: none;
}
.blue-base{
	color: #039BE5;
}
.gold-base{
	color: #dc873b;
}
.green-base{
	color: #009788;
}
.purple-base{
	color: #5F3575;
}
.red-base{
	color: #C54033;
}
.grey-base{
	color: #78909c;
}
.navbar{
	border: 0px;
	position: fixed;	
	padding-left: 0px;
	padding-right: 0px;
    height: 5.5em;
    .navbar-toggler{
    	outline: none!important;
    }
    .nav-left{
	    .dropdown-toggle:after {
			display: none;
		}
		.nav-item {
			text-align: center;
			height: 5.5em;
			width: 10.5em;
			margin-left: 0px;
			&.active{
			    background-color: $brand-primary;
				.icons {
					color: #fff!important;
				}
			}
			&:hover:not(.active){
				.navbar-titles {
					color: $brand-primary;
				}
			}
			&:hover{
				cursor: pointer;
			}
			.item-wrapper {
				position: relative;
				top: 8px;
				background-color: inherit;
				.icons {
					position: absolute;
					left: 0;
				    right: 0;
				    text-align: center;
				    color: $icons-color;
				}
				&:hover, &:focus, &:active{
					background-color: inherit!important;
					
				}
			}
			.navbar-titles {
				color: white;
				position: inherit;
				top: 30px;
				font-size: 14px;
				font-weight: 100;
			}

			.border-line{
				top: 18px;
			    height: 41px;
			    position: absolute;
			    background-color: #FFF;
			    border-right: 1px solid $icons-color;
			    opacity: 0.5;
			}
		}
	}
	.nav-right-outer:not(.small-screen){
	    position: relative;
	    width: 300px;
	    top: 3px;
		.nav-right{
			position: relative;
			width: 180px;
			.nav-item{
				height: 5em;
				margin-left: 0.4rem;
				.dropdown-menu{
					top: 74px;
					right: 0;
					left: initial;
				}
				
				.item-wrapper{
					&:not(.profile-item){
						padding-top: 24px;
					    background-color: transparent;
					}
					
					.profile-nav-item{
						padding-top: 6px;
					}
					.dropdown-toggle:after {
						border-top: 0.6em solid $brand-primary;
					}
					.icons{
						font-size: inherit;
					}
					.notify{
						top: 26px;
						position: absolute;
						height: 11px;
					    width: 11px;
					    border: 3px solid #373c4f;
					}
					.notify-msg{
						left: 14px;
					}
					&:hover{
						.icons{
							color: $brand-primary!important;
						}
					}
				}
			}
		}
		.border-line{
			right: 100%;
		    position: absolute;
			top: 18px;
		    height: 41px;
		    background-color: #FFF;
		    border-right: 1px solid $icons-color;
		    opacity: 1;
		}
		.digisale-title{
		    position: absolute;
		    color: white;
		    font-size: 30px;
		    top: 10px;
			right: 120%;
		}
	}
	.nav-dropdown-wrapper{
		width: 100%;
		position: absolute;
		top: 77px;
		left: 0px;
		.nav-dropdown{
			background-color: darken($brand-primary, 10%);
		    font-weight: 100;
		    padding-left: 0;
		    margin: 0px 0px;
		    line-height: 3em;
		}
		.nav-dropdown-items{
			display: inline-block;
			width: 10.5em;
		    margin-left: -2px;
			a, a:focus, a:hover, a:active{
				text-decoration: none;
				text-align: center;
				padding: 0px;
			}
			&:hover{
				background-color: darken($brand-primary, 20%);
			}
			&.close-dropdown{
				width: 2.5em;
				font-size: 18px;
				a{
					text-align: center;
					padding: 0px;
				}
				.fa-times{
				    margin: 0px;
				}
			}
			&.active{
				background-color: darken($brand-primary, 10%);
			}
		}
	}
	.buy-now-wrapper{
		position: absolute;
	    right: 340px;
	    top: 10px;
	    a {
	    	outline: none!important;
	    }
	}    
}
.theme-color-item{
    max-width: 25px;
    margin-right: 10px;
	.notoggle {
		padding: 5px!important;
	    background-color: transparent!important;
	    top: 20px;
	    font-size: 16px;
		&::after{
			display: none;
		}
	}
	.dropdown-menu{
	    min-width: 120px;
        padding: 0;
	    .color-item{
	    	height: 43px;
	    	a {
	    		display: inline-block!important;
			    padding: 3px 14px!important;
	    	}
	    }
	}
	.display-circle-icon {
		color: $brand-primary;
	}
}

@media screen and (max-width: 1430px) {
    .navbar .nav-right-outer:not(.small-screen){
    	width: initial!important;
    	.nav-right{
	    	width: initial!important;
	    	.image-item{
	    		.dropdown-menu{
	    			left: initial!important;
	    			right: 0px!important;
	    		}
	    	}    		
    	}
		.nav-item:not(.image-item):not(.theme-color-item) {
        	display: none;
        }
    }
	.buy-now-wrapper{
	    right: 120px!important;
	}    
}
@media screen and (max-width: 1210px) {
    .navbar{ 
	    .nav-left .nav-item {
		    width: 8em!important;
		}
		.nav-dropdown-wrapper .nav-dropdown-items:not(.close-dropdown) {
		    width: 8em!important;
		    padding-left: 10px;
		    a {
		    	text-overflow: ellipsis;
			    overflow: hidden;
			    white-space: nowrap;
		    }
		}
	}
}
@media screen and (max-width: 980px) {
	.buy-now-wrapper{
	    right: 70px!important;
	}    
	.theme-color-item{
		display: none!important;
	}
	.navbar{ 
	    .nav-left .nav-item {
		    width: 96px!important;
		    .navbar-titles {
			    font-size: 11px;
			}
		}
		.nav-dropdown-wrapper .nav-dropdown-items:not(.close-dropdown) {
		    width: 111px!important;
		    padding-left: 10px;
		    a {
		    	text-overflow: ellipsis;
			    overflow: hidden;
			    white-space: nowrap;
		    }
		}
	}
}
@media screen and (max-width: 830px) {
	.buy-now-wrapper{
		display: none;
	}
}
@media screen and (max-width: 767px) {
	.theme-color-item{
		display: none!important;
	}
	.navbar{
	    .navbar-toggler {
	    	line-height: 2.9;
	    }
	    .nav-left .nav-item {
		    width: 100%!important;
		}
		.nav-left{
			margin: 0;
		}
		.border-line{
			display: none;
		}
		:not(.small-screen){
			.nav-right{
				right: 20px;
			}			
		}
		.top-menu{
	    	display: none!important;
	    }
	}
	.nav-right-outer:not(.small-screen){
		display: none;
	}
	.small-screen{
		display: block;
		position: absolute;
	    top: 0px;
	    right: 0;
	    .color-dropdown{
	    	position: absolute!important;
	    	background-color: #fff!important;
			right: 0;
			left: initial;
			top: 77px;
			border: 1px solid rgba(0, 0, 0, 0.15)!important;
		    border-radius: 7px;
		    .theme-picker{
		    	padding-left: 15px!important;
		    	&:hover{
	    		    background-color: $gray-lighter !important;
		    	}
		    }
	    }
	    .nav-right{
			margin: 0;
	        padding: 0px;
			.nav-item{
				height: 5em;
				
				.item-wrapper{
					&:not(.profile-item){
						padding-top: 24px;
					    background-color: transparent;
					}
					
					.profile-nav-item{
						padding-top: 6px;
						box-shadow: none;
						img{
							height: 60px;
						}
					}
					&.profile-item{
						.dropdown-menu{
							position: absolute;
							background-color: #fff;
							right: 0;
						    left: initial;
						    top: 77px;
						}
					}
					.dropdown-toggle:after {
						border-top: 0.6em solid $brand-primary;
					}
					.icons{
						font-size: inherit;
					}
					.notify{
						top: 26px;
						position: absolute;
						height: 11px;
					    width: 11px;
					    border: 3px solid #373c4f;
					}
					.notify-msg{
						left: 14px;
					}
					&:hover{
						.icons{
							color: $brand-primary!important;
						}
					}
				}
			}
		}
	}
	.side-menu{
		display: block;
	    min-height: 100vh;
    	background-color: $navbar-inverse-color;
	    max-width: 250px;
		position: fixed;
		z-index: 999;
    	.nav-left{
			width: 100%;
			margin: 0px;
			padding: 10px 0px;
			li.nav-item {
			    width: 100%;
			    margin: 0px 0!important;
			    .item-wrapper{
			    	padding: 13px 1.1rem;
				    font-size: 17px;
		    		color: #fff;
			    	&:hover , &:focus, &:active{
			    		background-color: $brand-primary;
					}
					&::after{
						display: none;
					}
					.fa-angle-up{
						display: none;
					}
			    }
				.nav-dropdown{
					background-color: darken($brand-primary, 10%);
					list-style-type: none;
				    padding: 0px 0px;
				    li.nav-dropdown-items {
					    padding: 0px 0;
					    font-size: 16px;
					    &:hover{
							background-color: darken($brand-primary, 15%);
						}
					    a {
					    	padding: 10px 20px;
					    }
					}
				}
				&.active{
					.item-wrapper{
						background-color: $brand-primary;
			    		color: #fff;
						.fa-angle-down{
							display: none!important;
						}
						.fa-angle-up{
							display: block!important;
						}
					}	
				}
			}
		}
    }
    
}
